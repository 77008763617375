<template>
  <v-container>
    <v-row>
      <v-col
        cols="5"
      >
        <v-text-field
          v-model="nouvelleCouleur"
          label="Nouvelle couleur"
          outlined
        />
      </v-col>
      <v-col
        cols="5"
      >
        <v-btn
          big
          color="edit"
          @click="AddOneColor()"
        >
          Enregistrer
        </v-btn>
      </v-col>
    </v-row>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="location in locations"
        :key="location.id"
      >
        <v-expansion-panel-header v-slot="{ open }">
          <v-row no-gutters>
            <v-col cols="4">
              {{ location.label }}
            </v-col>
            <v-col
              cols="8"
              class="text--secondary"
            >
              <v-fade-transition leave-absolute>
                <span
                  v-if="open"
                  key="0"
                >
                  Selectionnez une couleur
                </span>
                <span
                  v-else
                  key="1"
                />
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-select
                v-model="location.correspondance"
                :items="allColor"
                attach
                chips
                label="Chips"
                multiple
              />
              <v-btn
                small
                color="edit"
                style="background-color: grey;"
                @click="saveOneColor(location)"
              >
                Enregistrer
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  // import algoliasearch from 'algoliasearch'
  // import 'instantsearch.css/themes/algolia-min.css'

  import Vue from 'vue'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  Vue.use(VueAxios, axios)

  export default {
    data: () => ({
      date: null,
      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      locations: [],
      allColor: [],
      allColorFromAlgolia: [],
      allColorFromDataBase: [],
      nouvelleCouleur: [],
    }),
    created () {
      // this.locations = [{ label: 'Australia', correspondance: ['Black'] }, { label: 'Barbados', correspondance: [] }, { label: 'Chile', correspondance: [] }, { label: 'Denmark', correspondance: [] }, { label: 'Ecuador', correspondance: [] }, { label: 'France', correspondance: [] }]
      this.allColor = [
        'CHROME LOOK',
        'POLIE',
        'NOIR MAT',
        'NOIR BRILLANT ',
        'GRIS',
        'GRAPHITE FONCE MAT ',
        'BRONZE MAT BROSSE',
        'BRONZE MAT',
        'ARGENT BROSSE',
        'ANODISE NOIR',
        'OR',
        'ARGENT BRILLANT',
        'NOIR BORD POLI',
        'NOIR SATIN',
        'ARGENT PLATINE',
        'BRONZE SATIN',
        'NOIR',
        'Black',
        'Silver',
        '-kleur-oe-',
        'ARGENT',
        '-kleur-de-',
        '-kleur-dx-',
        'Silver Bright',
        'Dull Black',
        'NOIR SATIN BORD POLI',
        'ARGENT BORD POLI',
        'SILBER/SILBER',
        'SCHWARZ/MATT BLACK',
        'SILBER/POLARSILBER',
        'Hyper Silver',
        'SILBER/SILVER',
        'SCHWARZ/DIAMANT-SCHWARZ',
        'SILBER/POLAR-SILBER',
        'Black mat',
        'GRAU/ANTHRAZIT/GRAPHIT',
        'SCHWARZ/SCHWARZ MATT',
        'SCHWARZ/RACING-SCHWARZ',
        'SILBER/KRISTALLSILBER',
        'Anthracite / Polish',
        'Gloss Black',
        'GRAU/ANTHRAZIT/GREY',
        'SCHWARZ/BLACK FLAT',
        'SCHWARZ/SCHWARZ POLIERT',
        'GRIS MAT ',
        'GRAU/ANTHRAZIT/TITANIUM',
        'Crystal Silver',
        'GRAU/ANTHRAZIT/DARK GREY',
        'SCHWARZ/SCHWARZ-GLANZ',
        'SILBER/BRILLIANTSILBER',
        'SCHWARZ/BLACK GLOSSY',
        'NOIR BRILLANT FACE POLIE',
        'NOIR SATIN FACE POLIE',
        'NOIR BRILLANT FACE POLIE NOIRE',
        'Gloss Gray',
        'SILBER/METALL SILBER',
        'SILBER/RACE SILBER',
        'GRIS FACE POLIE',
        'GRAU/ANTHRAZIT/METAL-GREY',
        'GRAU/ANTHRAZIT/DAYTONA GRAU',
        'SCHWARZ/MATTSCHWARZ',
        'Anthracite',
        'Anthracite Dark',
        'GRAU/ANTHRAZIT/FERRIC-GREY-MATT',
        'SCHWARZ/D-SCHWARZ FR.POL',
        'SCHWARZ/SATIN BLACK MATT',
        'NOIR FACE POLIE',
        'Gloss Black / Polished',
        'GRIS SATIN FACE POLIE',
        'Poli Black',
        'MAT TITANE FACE POLIE',
        'SILBER/CRYSTAL SILVER',
        'Dull Anthracite',
        'GRIS SATIN',
        'GRAU/ANTHRAZIT/TITAN MATT',
        'Anthracite mat',
        'SCHWARZ/SCHWARZ-MATT',
        'Gold',
        'Gunmetal Dull',
        'Titanium',
        'NOIR BRILANT FACE POLIE NOIRE',
        'GRIS BRILLANT FACE POLIE',
        'Dull Black / Red',
        'Gloss Black / Red',
        'GRAU/ANTHRAZIT/RACING-GRAU',
        'NOIR BRILLANT',
        'Poli Anthracite',
        'SCHWARZ/SILKY BLACK',
        'Dull Anthracite / Polish',
        'SCHWARZ/DIAMANT-SCHW FPOL',
        'Dull Silver / Polished',
        'SCHWARZ/SW.R.POL RAND ROT',
        'SCHWARZ/SW.R.POL RAND SW',
        'SCHWARZ/SW POL RAND SCHW',
        'SCHWARZ/SW POL RAND ROT',
        'Hyper silver',
        'SCHWARZ/BLACK DIAMOND',
        'High Gloss',
        'GRAU/ANTHRAZIT/M.ANTHRAZIT GLOSSY',
        'Titane',
        'ARGENT MAT',
        'Poli black mat',
        'Grey',
        'SILBER/K-SILBER MATT',
        'GRAU/ANTHRAZIT/DARK GREY MATT',
        'White',
        'Silver / Polished',
        'NOIR%2FROUGE',
        'GRIS BRILLANT TOUR INTERNE ROUGE',
        'Hyper Black',
        'Poli anthracite mat',
        'SCHWARZ/DIA-SCHW KONTUR P',
        'GRIS BRILLANT',
        'MAT TITANE',
        'SILBER/ARKTIC SILBER',
        'Poli Silver',
        'GRAU/ANTHRAZIT/DARK GUN METAL',
        'Dull Black / Polished',
        'Lip Anthracite mat',
        'SCHWARZ/SCHWARZ GL?NZEND',
        'Dull Bronze',
        'Lip Mat Black',
        'Black Satin',
        'SILBER/SILVER PAINTED',
        'SCHWARZ/SCHWARZGLANZ VOLLP',
        'Gloss Black / Polished lip',
        'SCHWARZ/BLACK SHINY',
        'ARGENT SATIN FACE POLIE',
        'Lip Anthracite',
        'Titane mat',
        'NOIR GLACE',
        'Chrome',
        'SCHWARZ/SCHWARZ HG POL.',
        'SCHWARZ/BLACK LIP POLISHED',
        'GRAU/ANTHRAZIT/DECOR TITAN',
        'ROUGE ANODIZE',
        'OR ANODIZE',
        'NOIR FACE POLIE NOIRE',
        'GRIS MIRROIR',
        'GRAU/ANTHRAZIT/GRAPHIT-FRONTPOL.',
        'WEISS/RALLYE-WEISS',
        'GRAU/ANTHRAZIT/GREY POLISHED',
        'Black red',
        'GRAU/ANTHRAZIT/GLOSSY ANTHRACITE',
        'Black / Polished',
        'Lip Black',
        'NOIR MAT BORD POLI',
        'ARGENT POLIE BORD POLI',
        'NOIR ET ROUGE',
        'Orange',
        'Gold / Polish',
        'LIP Silver',
        'MAT TITANE ',
        'SILBER/CRISTAL SILVER',
        'SCHWARZ/BLACK POLISHED',
        'SILBER/HIGH GLOSS SILVER',
        'SCHWARZ/GLOSSY BLACK',
        'Bronze',
        'GRAU/ANTHRAZIT/HIMALAYA-GREY-MATT',
        'GRIS MAT FACE POLIE',
        'GRAU/ANTHRAZIT/DAYTONA GRAU POL.',
        'Lip Poli Silver',
        'Poli red',
        'Poli Bronze',
        'Poli Blue',
        'FUME FACE POLIE',
        'BRONZE BRILLANT',
        'Light Gray Polished',
        'Bronze / Polished',
        'ARGENT FACE POLIE',
        'Hyper Silver / Polished',
        'Lip Gold',
        'SCHWARZ/DIAMANT-SCHW HPOL',
        'NOIR MAT FACE POLIE',
        'SCHWARZ/SCHWARZ',
        'GRAU/ANTHRAZIT/MATT ANTHR.DIAM.',
        'SCHWARZ/SATIN BLACK DIAM.',
        'Poli Hyper',
        'Silver / Stainless Steel',
        'Red',
        'Mat Bronze',
        'Hyper Grey',
        'GRAU/ANTHRAZIT/ANTHRACITE DIAMOND',
        'Bronze Mat Black',
        'SCHWARZ/BLACK PAINTED',
        'SCHWARZ/RACING-SCHW FPOL',
        'SCHWARZ/JETBLACK',
        'Silver mat',
        'SCHWARZ/BLACK RIM POLISHED',
        'GRAU/ANTHRAZIT/JETBLACK-MATT-FRKO',
        'Lip White',
        'SCHWARZ/SCHWARZ POL.MATT',
        'SILBER/BRILLANTSILBER',
        'GRAU/ANTHRAZIT/MATT ANTHRACITE',
        'SILBER/BRIGHT SILVER',
        'BLANC BRILLANT ',
        'GRAU/ANTHRAZIT/D.GUNMETAL POL',
        'BLANC LETTRE ROUGE',
        'GRIS LETTRE NOIRE',
        'SCHWARZ/SCHWARZMATT VOLL-P',
        'GRAU/ANTHRAZIT/GRAPHIT-POLIERT',
        'NOIR MAT LETTRE ROUGE',
        'SILBER/HIGH GLOSS SILBER',
        'GRAU/ANTHRAZIT/GRAPHITE POLISHED',
        'NOIR BRILLANT BORD POLI',
        'BLANC LETTRE BLEUE',
        'Black Orange',
        'Lip Red Black Mat',
        'Poli Grey',
        'Poli Gold',
        'NOIR CRISTAL',
        'SILBER/SILBER HORNPOLIERT',
        'SCHWARZ/MATTSCHWARZ LACK',
        'Poli Dark Gun',
        'GRAU/ANTHRAZIT/GUNMETAL POLISHED',
        'GRAU/ANTHRAZIT/DARK HYPERBLACK P.',
        'Black Red mat',
        'SILBER/DARK HYPERBLACK P.',
        'Lip Hyper Black',
        'Poli Orange',
        'GLOSS BLACK',
        'Poli HyperBlack',
        'SILBER/HYPERBLACK DIAMPOL',
        'Satin Bronze',
        'HYPER NOIR',
        'OR LETTRE BLEU',
        'Gloss Gray / Polished',
        'GRAU/ANTHRAZIT/TITANIUM DIAM POL',
        'BRONZE RED INSIDE',
        'GRAPHITE SMOKE MACHINED',
        'Poli White',
        'GRIS FONCE FACE POLIE',
        'Black Silver',
        'MATT RED FRONT POLISH',
        'SILBER/GRIGIO CORSA',
        'BRONZE ',
        '',
        'SCHWARZ/BLACK DIAMOND POL',
        'ARGENT PLATINE SATIN',
        'Black Blue',
        'TITANE CRISTAL',
        'GRAU/ANTHRAZIT/HIMA-GREY-VOLL-POL',
        'Graphite',
        'Carbon Graphite Polished Lip',
        'SCHWARZ/BLACK RIM POL.MATT',
        'Brushed Grey',
        'GRAU/ANTHRAZIT/DARK HYPERBL.CC P.',
        'SCHWARZ/JETBLACK FRONTPOL.',
        'ARGENT PLATINE FACE POLIE',
        'ARGENT MAT BROSSE',
        'Candy Red',
        'NOIR BORD BRONZE ',
        'Lip Poli Anthracite',
        'NOIR BRILLANT BORD POLI LETTRE ARGENT',
        'NOIR MAT FACE POLIE BROSSE BORD INOX ',
        'GRAU/ANTHRAZIT/DARK SPARKLE',
        'GRAU/ANTHRAZIT/MATTGRAPHITE',
        'SILBER/LICHTSILBER',
        'SILBER/STERLINGSILBER',
        'Anthracite Dark / Polished',
        'GRAPHITE FONCE MAT FACE POLIE',
        'MAT TITANE BORD POLI',
        'BLANC BORD POLI',
        'ARGENT MAT BROSSE BORD INOX',
        'Poli silver mat',
        'GRIS MAT LETTRE ARGENT',
        'ARGENT MAT LETTRE NOIRE',
        'NOIR MAT LETTRE ARGENT',
        'Poli Titane',
        'GRAPHITE FONCE LETTRE ARGENT',
        'Poli Blanc',
        'NOIR MAT LETTRE BLANCHE',
        'reflectic',
        'BLANC',
        'OR BRILLANT',
        'GRIS NOIR',
        'Black Polished Tinted',
        'Brushed Bronze',
        'Poli Mat Titane',
        'GRIS METAL BORD ET FACE POLIS',
        'GRIS METAL BORD ET FACE  POLIS',
        'GRIS METAL FACE POLIE',
        'TITANE',
        'Blue',
        'NOIR BRILLANT INSERT POLI',
        'TITANE BORD NOIR',
        'VERT ACIDE',
        'ROUGE',
        'ORANGE',
        'BLEU MAT',
        'NOIR MAT BORD TITANE',
        'BRONZE MAT BORD NOIR',
        'NOIR MAT FACE POLIE NOIRE',
        'ARGENT BORD ET FACE POLI',
        'GRIS MAT FACE POLIE NOIRE',
        'GRIS MAT BORD NOIR ',
        'SABLE',
        'Carbone',
        'NOIR MAT BORD POLIE',
        'ARGENT BRILLANT BORD POLI',
        'GRIS MAT BORD NOIR BRILLANT',
        'GRIS FACE POLIE GRIS  et  BORD POLI',
        'GRIS BRILLANT ',
        'NOIR MAT FACE POLIE TITANE',
        'Brushed Titane',
        'TITANE ARGENT FACE POLIE',
        'TITANE SATIN',
        'SCHWARZ/G.SCHWARZ POLIERT',
        'NOIR BRILLANT LETTRE ROUGE',
        'ARGENT BORD ET FACE  POLI',
        'GRIS PLATINE SATIN',
        'GRIS BRILLANT FACE POLIE NOIRE',
        'ARGENT FACE POLIE BROSSE',
        'GRAPHITE SATIN FACE POLIE',
        'SABLE BORD NOIR',
        'Lip Mat Titane',
        'NOIR SATIN BORD ROUGE',
        'GRAPHITE FONCE BORD POLI ',
        'Black Anthracite',
        'ARGENT FACE POLIE NOIRE',
        'MAT TITANE FACE POLIE NOIRE',
        'GRIS METAL FACE POLIE NOIRE',
      ]

      this.allColor.sort()
    },
    mounted () {
      if (this.$localStorage.get('connected') == null) {
        this.$router.push({ path: '/connexion' })
      }
      Vue.axios.get('https://api.espace-jantes.com/GetGestionCouleur')
        .then((response) => {
          this.allColorFromDataBase = response.data
          response.data.forEach(element => {
            this.locations.push(JSON.parse(element.valeur_config))
          })
        })
    },
    methods: {
      saveOneColor (color) {
        var idCouleur = this.getIdCouleur(color.label)
        Vue.axios.post('https://api.espace-jantes.com/UpdateOneCouleur',
                       {
                         params: { idCouleur: idCouleur, valeurCouleur: color },
                       },
        )
          .then((response) => {
          })
      },
      saveAllColors () {
        // console.log(this.locations)
      },
      AddOneColor () {
        // console.log(this.nouvelleCouleur)
        if (this.nouvelleCouleur.length > 0) {
          Vue.axios.post('https://api.espace-jantes.com/AddOneCouleur',
                         {
                           params: { nouvelleCouleur: this.nouvelleCouleur },
                         },
          )
            .then((response) => {
              this.$router.go(0)
            })
        } else {
          this.$router.go(0)
        }
      },
      getIdCouleur (label) {
        var currentColor = this.allColorFromDataBase.find(element => JSON.parse(element.valeur_config).label === label)
        return currentColor.id
      },
    },
  }
</script>
